import {
	Link,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useMatches,
} from '@remix-run/react';
//import tailwind from './styles/tailwind.css?url';
import styles from './styles/styles.css?url';
import { BookOpenIcon } from '@heroicons/react/24/outline';
export const links = () => {
	return [{ rel: 'stylesheet', href: styles }];
};

export const handle = {
	links: [],
	header: 'Bookshop',
};

export function Layout({ children }: { children: React.ReactNode }) {
	const matches = useMatches();
	const links = matches.map((m) => (m as any).handle?.links || []).flat(1);
	//const buttons = matches.map((m) => (m as any).handle?.buttons || []).flat(1);
	const header =
		matches
			.map((m) => (m as any).handle?.header || [])
			.flat(1)
			.pop() || '';

	return (
		<html lang="en">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="apple-touch-icon" href="" />
				<Meta />
				<Links />
			</head>
			<body className="flex flex-col gap-4 wombat">
				<header
					role="banner"
					className="flex justify-start gap-1 bg-amber-600 p-2 text-xl text-white"
				>
					<span className="icon">
						<BookOpenIcon />
					</span>
					<Link to="/">
						<span>Wombat Bookshop</span>
					</Link>
				</header>
				<main className="container gap-8 p-1 lg:p-0">
					<section className="flex gap-4">
						<h1 className="flex-1 text-3xl">{header}</h1>
						{links && (
							<nav>
								<ul className="flex gap-2">
									{links.map((b, i) => (
										<li key={i}>
											<Link to={b.to} rel="noreferrer" className="btn">
												{b.title}
											</Link>
										</li>
									))}
								</ul>
							</nav>
						)}
						{/*buttons && (
							<nav>
								<ul className="flex gap-2">
									{buttons.map((b, i) => (
										<li key={i}>
											<button onClick={b.onClick} className="btn">
												{b.title}
											</button>
										</li>
									))}
								</ul>
							</nav>
						)*/}
					</section>
					{children}
				</main>
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

export default function App() {
	return <Outlet />;
}
